enum type {
  primary = 'primary',
  secondary = 'secondary',
}
enum size {
  medium = 'medium',
  small = 'small',
}

export { type, size };
